/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import MenuSearchIconComponent from 'Component/MenuSearchIcon';
import SearchIcon from 'Component/SearchIcon';
import UserIcon from 'Component/UserIcon';
import { SUCCESS_URL } from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { lazyComponentLoader } from 'Util/lazyComponentLoader';

export const HamburgerMenu = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "navigation-extension" */
    '../component/HamburgerMenu'
), 2);

export class HeaderPlugin {
    stateMap = (originalMember) => Object.entries(originalMember)
        .reduce((prev, [page, icons]) => ({
            ...prev,
            [page]: {
                ...icons,
                menu: icons.close !== true,
                searchIcon: true,
                search: true,
                account: true,
                minicart: true,
                logo: true,
                back: false,
                title: false
            }
        }), {});

    renderMap = (originalMember, instance) => ({
        menu: this.renderOpenMenuButton.bind(instance),
        searchIcon: this.renderSearchIconMobile.bind(instance),
        ...originalMember,
        logo: this.renderLogoMobile.bind(instance),
        search: this.renderSearchFieldMobile.bind(instance),
        account: this.renderAccount.bind(instance),
        minicart: this.renderMiniCart.bind(instance),
        closeSearch: this.renderCloseSearchButton.bind(instance)
    });

    renderMapDesktop = (originalMember, instance) => ({
        searchIcon: this.renderSearchIcon.bind(instance),
        ...originalMember
    });

    renderMiniCart(isVisible) {
        const {
            isSearchBarActive,
            isCheckout,
            device: { isMobile }
        } = this.props;
        const {
            location: {
                pathname
            }
        } = history;

        if (isCheckout && !pathname.includes(SUCCESS_URL)) {
            return null;
        }

        if (!isMobile) {
            return null;
        }

        if (isSearchBarActive) {
            return null;
        }

        return this.renderMinicartButton(isVisible);
    }

    renderMenu(args, callback, instance) {
        const { isSearchBarActive } = instance.props;

        if (isSearchBarActive) {
            return null;
        }

        return callback(args);
    }

    renderHeaderLinks(args, callback, instance) {
        const { isSearchBarActive } = instance.props;
        if (isSearchBarActive) {
            return null;
        }

        return callback(args);
    }

    renderDesktopActions(args, callback, instance) {
        const { isSearchBarActive } = instance.props;
        if (isSearchBarActive) {
            return null;
        }

        return callback(args);
    }

    renderLogoMobile(isVisible) {
        const { isSearchBarActive, device: { isMobile } } = this.props;

        if (isSearchBarActive && isMobile) {
            return null;
        }

        return this.renderLogo(isVisible);
    }

    renderCloseSearchButton() {
        const {
            isSearchBarActive,
            onSearchBarDeactivate
        } = this.props;

        if (!isSearchBarActive) {
            return null;
        }

        return (
            <button
              block="Header"
              elem="CloseSearchBtn"
              key="closeSearch"
              onClick={ onSearchBarDeactivate }
              aria-label={ __('Cancel Search') }
            >
                <CloseIcon />
                <div className="CancelText">{ __('Cancel') }</div>
            </button>
        );
    }

    renderSearchIcon() {
        const {
            isSearchBarActive,
            onSearchButtonClick
        } = this.props;

        if (isSearchBarActive) {
            return null;
        }

        return (
            <button
              block="Header"
              elem="SearchBtn"
              key="searchBtn"
              onClick={ onSearchButtonClick }
              aria-label={ __('Start Search') }
            >
                <SearchIcon />
            </button>
        );
    }

    renderSearchIconMobile() {
        const {
            isSearchBarActive,
            onSearchButtonClick,
            device: { isMobile }
        } = this.props;

        if (isSearchBarActive || !isMobile) {
            return null;
        }

        return (
            <button
              block="Header"
              elem="SearchBtn"
              key="searchBtn"
              onClick={ onSearchButtonClick }
              aria-label={ __('Start Search') }
            >
                <SearchIcon />
            </button>
        );
    }

    renderSearchFieldMobile() {
        const { isSearchBarActive } = this.props;

        if (!isSearchBarActive) {
            return null;
        }

        return this.renderSearchField(true);
    }

    renderAccount() {
        const {
            onMobileMyAccountButtonClick,
            isSearchBarActive,
            device: { isMobile },
            isCheckout
        } = this.props;

        if (isMobile) {
            if (isSearchBarActive) {
                return null;
            }

            return (
                <button
                  block="Header"
                  elem="AccountBtn"
                  key="accountBtn"
                  onClick={ onMobileMyAccountButtonClick }
                  aria-label="Open my account"
                >
                    <UserIcon />
                </button>
            );
        }

        if (isCheckout && isSignedIn()) {
            return null;
        }

        return null;
    }

    renderOpenMenuButton() {
        const {
            openSideMenu,
            closeSideMenu,
            isSearchBarActive,
            device: { isMobile }
        } = this.props;

        if (isSearchBarActive || !isMobile) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ closeSideMenu }
              key="sideMenu"
            >
                <div block="Header" elem="HamburgerMenuWrapper">
                    <button
                      block="Header"
                      elem="OpenMenuBtn"
                      onClick={ openSideMenu }
                      aria-label={ __('Open Menu') }
                    >
                        <MenuSearchIconComponent />
                    </button>
                    <HamburgerMenu />
                </div>
            </ClickOutside>
        );
    }
}

const {
    renderMap, stateMap, renderMapDesktop, renderMenu, renderHeaderLinks, renderDesktopActions
} = new HeaderPlugin();

export default {
    'Component/Header/Component': {
        'member-property': {
            renderMapDesktop,
            renderMap,
            stateMap
        },
        'member-function': {
            renderDesktopActions,
            renderHeaderLinks,
            renderMenu
        }
    }
};
